import log from "loglevel";
import { useEffect, useState } from "react";
import { HARDENIZE_IFRAME_URL } from "../libs/constants";
import { modifySiftUrl } from "../libs/sift-utils";
import { getIam } from "../libs/utils";
import GetProduct from "./GetProduct";

const HardenizeIFrame = ({ jwt, pname }: { jwt: string; pname: string }) => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <iframe
        id={"hardenize"}
        style={{
          display: "block",
          border: "none",
          width: "calc(100vw - 67px)",
        }}
        src={`${HARDENIZE_IFRAME_URL}?token=${jwt}&redirect=${encodeURIComponent(
          pname
        )}`}
        title="Hardenize by Red Sift"
        referrerPolicy="no-referrer-when-downgrade"
        sandbox="allow-downloads allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        allow="fullscreen clipboard-read; clipboard-write"
        width="100%"
        height="100%"
      />
    </div>
  );
};

function validatePath(path: string) {
  try {
    const url = new URL(path, window.location.origin);
    return url.pathname.startsWith("/sift");
  } catch (e) {
    return false;
  }
}

const Hardenize = ({ product }: { product?: "asm" | "certificates" }) => {
  const [loading, setLoading] = useState(true);
  const [jwt, setJwt] = useState("");
  const [pname, setPname] = useState("/");

  useEffect(() => {
    const { pathname, search, hash } = window.location;
    if (
      pathname.split("/").slice(3).join("/") === "" &&
      product === "certificates"
    ) {
      log.debug(
        "path name is empty and product is certificates, setting to certificates"
      );
      setPname(
        `/org/-/certificates/` + (search ? search : "") + (hash ? hash : "")
      );
    } else {
      setPname(
        "/" +
          pathname.split("/").slice(3).join("/") +
          (search ? search : "") +
          (hash ? hash : "")
      );
    }
    log.info("Hardenize::useEffect");
    getIam("/api/admin/hardenize").then((resp) => {
      log.debug("Hardenize::got response:", resp);
      // TODO: need to implement an org selector in case of multiple orgs
      if (resp?.orgs?.length > 0) {
        setJwt(resp.orgs[0].jwe);
      }
      setLoading(false);
    });
    window.onmessage = (event: any) => {
      const { method, params } = event.data;

      if (method === "notifyClient") {
        const { topic, value } = params;
        switch (topic) {
          case "sync-history":
            modifySiftUrl(
              { guid: product || "hardenize", instance: "" },
              value.action || "push",
              value
            );
            break;
          case "redirect":
            // TODO: this should work for now but value should contain the { service: "app", path: "/sift/hardenize" }
            // We need to check path for any open redirects and take the user to that place instead
            if (!value) {
              log.warn("Hardenize::no value provided:", value);
              window.location.reload();
              break;
            }
            const { service, path } = value;
            switch (service) {
              case "app":
                window.location.reload();
                break;
              case "sift":
                // Only accept relative paths that start with /sift to prevent open redirects
                if (path && path.startsWith("/sift") && validatePath(path)) {
                  window.location.href = path;
                  break;
                }
                log.error("Hardenize::unexpected path for sift:", path);
                window.location.reload();
                break;
              default:
                log.warn("Hardenize::unexpected service:", service);
                window.location.reload();
            }
          default:
            log.debug("Hardenize::unexpected topic:", topic, value);
        }
      } else {
        log.debug("Hardenize::unexpected method:", method);
      }
    };
  }, [product]);

  if (loading) {
    return null;
  }

  return jwt ? (
    <HardenizeIFrame jwt={jwt} pname={pname} />
  ) : (
    <GetProduct product={product || "hardenize"} />
  );
};

export default Hardenize;
