import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MUITextField from "@mui/material/TextField";
import log from "loglevel";
import { useContext, useMemo, useState } from "react";
import { ALL_SIFTS } from "../libs/constants";
import { getIam, postIam, putIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import dayjs from "dayjs";
import { Combobox } from "@redsift/pickers";
import {
  Button,
  Flexbox,
  Icon,
  Item,
  Text,
  TextField,
} from "@redsift/design-system";
import { mdiAccountStar, mdiSourceCommit } from "@redsift/icons";
import { Dialog, Tooltip } from "@redsift/popovers";

const getSifts = async (
  product: string,
  ctx: any,
  setSifts: Function,
  email?: string,
  siftId?: string
) => {
  const resp = await getIam("/api/admin/sifts", {
    guid: product,
    email,
    siftId,
  });
  if (resp.error) {
    log.error("getSifts::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setSifts(resp);
  }
};

const getPublished = async (product: string, setPublished: Function) => {
  const resp = await getIam("/api/admin/update", {
    guid: product,
  });
  if (resp.error) {
    log.error("getPublished::", resp);
  } else {
    log.debug("getPublished::", resp);
    setPublished(resp);
  }
};

const stopSiftUpdates = async (product: string, ctx: any) => {
  const resp: any = await getIam(`/api/admin/update/stop?guid=${product}`);
  if (resp.error) {
    log.error("stopSiftUpdates::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Updates stopped.", "success", 2000);
  }
};

const updateSift = async (
  product: string,
  siftIds: any[],
  sha: string,
  siftConcurrency: string,
  ctx: any
) => {
  const resp: any = await postIam(
    `/api/admin/update?concurrency=${siftConcurrency}`,
    {
      guid: product,
      siftIds,
      sha,
    }
  );
  if (resp.error) {
    log.error("updateSift::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Update completed", "success", 2000);
  }
};

const setDefaultPublishedSHA = async (
  product: string,
  sha: string,
  ctx: any
) => {
  const resp: any = await putIam(
    `/api/admin/publish/default?guid=${product}&sha=${sha}`,
    {}
  );
  if (resp.error) {
    log.error("setDefaultPublishedSHA::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Successfully marked as default", "success", 2000);
  }
};

const getAdminCreds = async (
  siftId: string,
  guid: string,
  ctx: any,
  setAdminSift: Function
) => {
  const resp = await getIam("/api/admin/sift", {
    siftId,
    guid,
  });
  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    log.debug("AdminSiftExplorer::manage", resp);
    setAdminSift(resp);
  }
};

const softDeleteSift = async (
  product: string,
  siftId: string,
  note: string,
  ctx: any
) => {
  const resp: any = await postIam(
    `/api/admin/sifts/${siftId}/${product}/delete`,
    {
      note,
    }
  );
  if (resp.error) {
    log.error("deleteSift::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Marked for deletion", "success", 2000);
  }
};

const AdminSiftExplorer = ({ setAdminSift }: { setAdminSift: Function }) => {
  const ctx = useContext(AppContext);
  const [isManageLoading, setIsManageLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isUpdateAllConfirmationOpen, setIsUpdateAllConfirmationOpen] =
    useState(false);
  const [excludeSiftIds, setExcludeSiftIds] = useState("");
  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] =
    useState(false);
  const [selectedSiftForDeletion, setSelectedSiftForDeletion] =
    useState<string>("");
  const [deletionNote, setDeletionNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState("");
  const [email, setEmail] = useState("");
  const [siftId, setSiftId] = useState("");
  const [sifts, setSifts] = useState([]);
  const [published, setPublished] = useState<any>(undefined);
  const [selectedSha, setSelectedSha] = useState<any>();

  const [isStopLoading, setIsStopLoading] = useState(false);
  const [siftUpdateConcurrency, setSiftUpdateConcurrency] = useState("1");
  const [isStopSiftUpdatesDialogOpen, setIsStopSiftUpdatesDialogOpen] =
    useState(false);

  const [isSettingNewDefaultSha, setIsSettingNewDefaultSha] =
    useState<any>(undefined);
  const [openVersionSelector, setOpenVersionSelector] =
    useState<boolean>(false);
  const [defaultSha, setDefaultSha] = useState<string>("");
  const handleProductChange = (event: SelectChangeEvent) => {
    setSifts([]);
    setPublished(undefined);
    setProduct(event.target.value as string);
  };

  const columns = useMemo(
    () => [
      {
        field: "_id",
        headerName: "MongoDb Id",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "id",
        headerName: "Sift Id",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "sha",
        headerName: "SHA",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "identity",
        headerName: "Sift Identity",
        flex: 1,
        renderCell: ({ value }: any) => {
          return <TooltipCell value={value} />;
        },
      },
      {
        field: "postInstallStatus",
        headerName: "Big Query Status",
        flex: 1,
        valueGetter: ({ value }: any): any =>
          value?.bigquery?.status
            ? `${value?.bigquery?.status}${
                value?.bigquery?.error ? ` ${value?.bigquery?.error}` : ""
              }`
            : "",
        renderCell: ({ value }: any) => {
          return <TooltipCell value={value} />;
        },
      },
      {
        field: "emailRoles",
        headerName: "Email : Roles",
        flex: 1,
        valueGetter: ({ value }: any): any => `${value.join(" | ")}`,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "uids",
        headerName: "User Ids",
        flex: 1,
        valueGetter: ({ value }: any) => `${value.join(" | ")}`,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "metadata.name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.name,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.name} />
        ),
      },
      {
        field: "metadata.description",
        headerName: "Description",
        valueGetter: ({ row }: any) => row.metadata?.description,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.description} />
        ),
      },
      {
        field: "metadata.userCount",
        headerName: "User Count",
        valueGetter: ({ row }: any) => row.metadata?.userCount,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.userCount} />
        ),
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          if (!row?.createdAt) {
            return null;
          } else {
            return new Date(row.createdAt);
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={
              value ? dayjs(value).format("DD/MM/YYYY") : "No Date Available"
            }
          />
        ),
      },
      {
        field: "deletedAt",
        headerName: "Soft deleted Date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          if (!row?.deletedAt) {
            return null;
          } else {
            return new Date(row.deletedAt);
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={value ? dayjs(value).format("DD/MM/YYYY") : "-"}
          />
        ),
      },
      {
        field: "manage",
        headerName: "Manage",
        renderCell: ({ row }: any) =>
          ALL_SIFTS[product].canAdmin && (
            <LoadingButton
              disabled={Boolean(row.deletedAt)}
              loading={isManageLoading}
              onClick={async () => {
                setIsManageLoading(true);
                await getAdminCreds(row.id, product, ctx, setAdminSift);
                setIsManageLoading(false);
              }}
              variant="outlined"
            >
              Manage
            </LoadingButton>
          ),
      },
      {
        field: "update",
        headerName: "Update",
        renderCell: ({ row }: any) => (
          <LoadingButton
            loading={isUpdateLoading}
            onClick={async () => {
              setIsUpdateLoading(true);
              await updateSift(
                product,
                [[row.id, row.emailRoles]],
                selectedSha,
                siftUpdateConcurrency,
                ctx
              );
              await getSifts(product, ctx, setSifts, email, siftId);
              setIsUpdateLoading(false);
            }}
            variant="contained"
            color="error"
          >
            Update
          </LoadingButton>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        renderCell: ({ row }: any) => (
          <LoadingButton
            disabled={Boolean(row.deletedAt)}
            loading={isUpdateLoading}
            onClick={() => {
              setIsDeletionConfirmationOpen(true);
              setSelectedSiftForDeletion(row.id);
            }}
            variant="contained"
            color="error"
          >
            Delete
          </LoadingButton>
        ),
      },
    ],
    [
      product,
      isManageLoading,
      ctx,
      setAdminSift,
      selectedSha,
      isUpdateLoading,
      email,
      siftId,
    ]
  );

  return (
    <div>
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          await getSifts(product, ctx, setSifts, email, siftId);
          await getPublished(product, setPublished);
          console.log("published", published);
          setLoading(false);
        }}
      >
        <FormControl style={{ minWidth: 200, margin: "5px 0px 0px 0px" }}>
          <InputLabel id="product-selector-label">Product</InputLabel>
          <Select
            labelId="product-selector-label"
            id="product-selector"
            value={product}
            label="Product"
            onChange={handleProductChange}
            required
          >
            {Object.keys(ALL_SIFTS)
              .sort((a: any, b: any) =>
                ALL_SIFTS[a].name.toLowerCase() >
                ALL_SIFTS[b].name.toLowerCase()
                  ? 1
                  : -1
              )
              .map((k, i) => (
                <MenuItem key={i} value={k}>
                  {ALL_SIFTS[k].name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <MUITextField
          id="email-search"
          label="email (partial match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <MUITextField
          id="sift-id-search"
          label="sift id (strict match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          value={siftId}
          onChange={(ev) => setSiftId(ev.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Find
        </LoadingButton>
      </form>
      {!!published && (
        <div
          style={{ display: "flex", marginTop: 30, justifyContent: "flex-end" }}
        >
          {published.length ? (
            <>
              <Button
                onClick={() => {
                  setOpenVersionSelector(true);
                }}
                style={{ margin: "0px 5px 0px 5px" }}
              >
                Set Default
              </Button>
              <Combobox value={selectedSha} onChange={setSelectedSha}>
                <Combobox.Trigger openOnFocus>
                  <TextField label="Published SHAs" style={{ minWidth: 360 }} />
                </Combobox.Trigger>
                <Combobox.Content>
                  <Combobox.Content.Header
                    margin="16px 16px 8px"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    <Text fontSize="14px" lineHeight="16px">
                      <strong>Current default SHA: </strong>
                      {
                        <Tooltip>
                          <Tooltip.Trigger style={{ marginRight: "5px" }}>
                            {published
                              .find(
                                (p: { sift: { default: boolean } }) =>
                                  p?.sift?.default
                              )
                              ?.sift?.sha?.slice(0, 20)}
                            {"..."}
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {
                              published.find(
                                (p: { sift: { default: boolean } }) =>
                                  p?.sift?.default
                              )?.sift?.sha
                            }
                          </Tooltip.Content>
                        </Tooltip>
                      }
                    </Text>
                  </Combobox.Content.Header>

                  <Combobox.Content.Listbox>
                    {published?.map(
                      (item: {
                        sift: {
                          sha: string;
                          branch: string;
                          guid: string;
                          identity: string;
                          default?: boolean;
                          description?: string;
                        };
                      }) => (
                        <Item key={item.sift.sha} value={`${item.sift.sha}`}>
                          {item?.sift?.default && (
                            <Tooltip>
                              <Tooltip.Trigger>
                                <Icon icon={mdiAccountStar}></Icon>
                              </Tooltip.Trigger>
                              <Tooltip.Content>
                                Default Version. New accounts created will pick
                                this up
                              </Tooltip.Content>
                            </Tooltip>
                          )}
                          <Tooltip>
                            <Tooltip.Trigger style={{ marginRight: "5px" }}>
                              {item.sift?.sha?.slice(0, 6)}...
                            </Tooltip.Trigger>
                            <Tooltip.Content>{item.sift?.sha}</Tooltip.Content>
                          </Tooltip>
                          <Tooltip>
                            <Tooltip.Trigger>
                              {item.sift?.branch?.slice(0, 20)}
                              {item.sift?.branch?.length > 20 ? "..." : ""}
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                              {item.sift?.branch}
                            </Tooltip.Content>
                          </Tooltip>
                          {item.sift?.description && (
                            <Tooltip>
                              <Tooltip.Trigger>
                                {" | "}
                                {item.sift?.description?.slice(0, 10)}
                                {(item.sift?.description?.length || 0) > 10
                                  ? "..."
                                  : ""}
                              </Tooltip.Trigger>
                              <Tooltip.Content>
                                {item.sift?.description}
                              </Tooltip.Content>
                            </Tooltip>
                          )}
                        </Item>
                      )
                    )}
                  </Combobox.Content.Listbox>
                </Combobox.Content>
              </Combobox>
            </>
          ) : (
            <TextField
              label="Published SHA"
              style={{ minWidth: 380 }}
              value={published?.sha}
              isReadOnly={true}
            />
          )}
          <Tooltip>
            <Tooltip.Trigger>
              <Button
                style={{ margin: "0px 5px 0px 5px" }}
                variant="secondary"
                isLoading={isUpdateLoading}
                onClick={async () => {
                  setIsUpdateLoading(true);
                  const all: any[] = [];
                  sifts.forEach((s: any) => {
                    if (s.sha !== selectedSha) {
                      all.push([s.id, s.emailRoles]);
                    }
                  });
                  await updateSift(
                    product,
                    all,
                    selectedSha,
                    siftUpdateConcurrency,
                    ctx
                  );
                  await getSifts(product, ctx, setSifts, email, siftId);
                  setIsUpdateLoading(false);
                  
                }}
              >
                Update Old
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>
              Updates sifts that are not on the selected SHA
            </Tooltip.Content>
          </Tooltip>
          <Tooltip>
            <Tooltip.Trigger>
              <Button
                isLoading={isUpdateLoading}
                onClick={async () => {
                  setIsUpdateAllConfirmationOpen(true);
                }}
                color="error"
              >
                Update All
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>
              Updates all sifts to the selected SHA
            </Tooltip.Content>
          </Tooltip>
          <Button
            color="error"
            variant="secondary"
            style={{ margin: "0px 5px 0px 5px" }}
            onClick={async () => {
              setIsStopSiftUpdatesDialogOpen(true);
            }}
          >
            Stop
          </Button>
        </div>
      )}
      <SearchableTable
        tableId="sifts-explorer"
        columns={columns}
        rows={sifts}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false,
              uids: false,
              "metadata.description": false,
              "metadata.userCount": false,
              "metadata.name": false,
              deletedAt: false,
              identity: false,
              postInstallStatus: false,
              delete: false,
            },
          },
        }}
      />
      <Dialog
        isOpen={openVersionSelector}
        onOpen={(open) => {
          setOpenVersionSelector(open);
          if (!open) {
            setDefaultSha("");
          }
        }}
        size={"large"}
      >
        {published?.length && (
          <Dialog.Content>
            <Dialog.Content.Header header="Set default sift version" />

            <Dialog.Content.Body>
              This will cause new installations to pick up this version
              <div key={product}>
                <Combobox
                  onChange={(value) => {
                    console.log("setting default sha", value);
                    setDefaultSha(value);
                  }}
                >
                  <Combobox.Trigger openOnFocus>
                    <TextField
                      label="Published SHAs"
                      style={{ minWidth: 720 }}
                    />
                  </Combobox.Trigger>
                  <Combobox.Content>
                    <Combobox.Content.Header
                      margin="16px 16px 8px"
                      display="flex"
                      flexDirection="column"
                      gap="8px"
                    >
                      <Text fontSize="14px" lineHeight="16px">
                        <strong>Current default SHA: </strong>
                        {
                          <Tooltip>
                            <Tooltip.Trigger style={{ marginRight: "5px" }}>
                              {
                                published.find(
                                  (p: { sift: { default: boolean } }) =>
                                    p?.sift?.default
                                )?.sift?.sha
                              }
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                              {
                                published.find(
                                  (p: { sift: { default: boolean } }) =>
                                    p?.sift?.default
                                )?.sift?.sha
                              }
                            </Tooltip.Content>
                          </Tooltip>
                        }
                      </Text>
                    </Combobox.Content.Header>

                    <Combobox.Content.Listbox>
                      {published?.map(
                        (item: {
                          sift: {
                            sha: string;
                            branch: string;
                            guid: string;
                            identity: string;
                            default?: boolean;
                            description?: string;
                            publishedAt: string;
                            name?: string;
                          };
                        }) => (
                          <Item key={item.sift.sha} value={`${item.sift.sha}`}>
                            {item?.sift?.default && (
                              <Tooltip>
                                <Tooltip.Trigger>
                                  <Icon icon={mdiAccountStar}></Icon>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                  Default Version. New accounts created will
                                  pick this up
                                </Tooltip.Content>
                              </Tooltip>
                            )}
                            <span
                              style={{
                                marginRight: "5px",
                              }}
                            >
                              {item.sift.sha} |
                            </span>
                            <Tooltip>
                              <Tooltip.Trigger>
                                {item.sift?.branch?.slice(0, 25)}
                                {item.sift?.branch?.length > 25 ? "... " : " "}|
                              </Tooltip.Trigger>
                              <Tooltip.Content>
                                {item?.sift?.branch}
                              </Tooltip.Content>
                            </Tooltip>
                            {item.sift?.description && (
                              <Tooltip>
                                <Tooltip.Trigger>
                                  {item.sift?.description?.slice(0, 10)}
                                  {item.sift?.description?.length > 10
                                    ? "... "
                                    : " "}
                                  |
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                  {item?.sift?.description}
                                </Tooltip.Content>
                              </Tooltip>
                            )}
                          </Item>
                        )
                      )}
                    </Combobox.Content.Listbox>
                  </Combobox.Content>
                </Combobox>
              </div>
            </Dialog.Content.Body>
            <Dialog.Content.Actions justifyContent="space-between">
              <Flexbox>
                <Button
                  isDisabled={!Boolean(defaultSha)}
                  isLoading={isSettingNewDefaultSha}
                  onClick={async () => {
                    setIsSettingNewDefaultSha(true);
                    await setDefaultPublishedSHA(product, defaultSha, ctx);
                    setOpenVersionSelector(false);
                    setIsSettingNewDefaultSha(false);
                    await getPublished(product, setPublished);
                  }}
                >
                  Set default
                </Button>
              </Flexbox>
            </Dialog.Content.Actions>
          </Dialog.Content>
        )}
      </Dialog>
      <Dialog
        isOpen={isDeletionConfirmationOpen}
        onOpen={setIsDeletionConfirmationOpen}
        size="medium"
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            <strong>{selectedSiftForDeletion} </strong>
            <br />
            Why is the account being deleted?
            <div>
              <TextField
                style={{ marginTop: 10 }}
                width={400}
                value={deletionNote}
                label="Note"
                onChange={(value) => {
                  setDeletionNote(value || "");
                }}
              />
            </div>
            <div>
              <Button
                style={{ marginTop: 10 }}
                disabled={!deletionNote || !selectedSiftForDeletion}
                isLoading={isDeleteLoading}
                onClick={async () => {
                  setIsDeleteLoading(true);
                  await softDeleteSift(
                    product,
                    selectedSiftForDeletion,
                    deletionNote,
                    ctx
                  );
                  setIsDeleteLoading(false);
                  await getSifts(product, ctx, setSifts, email, siftId);
                  setDeletionNote("");
                  setIsDeletionConfirmationOpen(false);
                  setIsDeleteLoading(false);
                  setSelectedSiftForDeletion("");
                }}
              >
                Confirm deletion
              </Button>
            </div>
          </Dialog.Content.Body>
        </Dialog.Content>
      </Dialog>
      <Dialog
        isOpen={isUpdateAllConfirmationOpen}
        onOpen={setIsUpdateAllConfirmationOpen}
        size="medium"
      >
        <Dialog.Content>
          <Dialog.Content.Body>
            <strong>Selected SHA: {selectedSha} </strong>
            <br />
            Exclude any Sift IDs from the update (comma separated):
            <div>
              <TextField
                style={{ marginTop: 10 }}
                width={400}
                value={excludeSiftIds}
                label="Exclude Sift IDs"
                onChange={(value) => {
                  setExcludeSiftIds(value || "");
                }}
              />
            </div>
            <br />
            Choose number of sift updates to run concurrently:
            <br />
            <TextField
              label="Update Concurrency"
              style={{ marginTop: 10 }}
              isInvalid={!Boolean(parseInt(siftUpdateConcurrency))}
              onChange={(value) => {
                if (value) {
                  setSiftUpdateConcurrency(value);
                }
              }}
              type="number"
              value={siftUpdateConcurrency}
            />
            <br />
            <div>
              <Button
                style={{ marginTop: 10 }}
                disabled={!selectedSha}
                isLoading={isUpdateLoading}
                onClick={async () => {
                  setIsUpdateLoading(true);
                  const exclude = excludeSiftIds
                    ?.split(",")
                    .map((s) => s.trim());
                  const all: any[] = sifts
                    .filter((s: any) => !exclude.includes(s.id))
                    .map((s: any) => [s.id, s.emailRoles]);

                  setIsUpdateAllConfirmationOpen(false);
                  await updateSift(
                    product,
                    all,
                    selectedSha,
                    siftUpdateConcurrency,
                    ctx
                  );
                  await getSifts(product, ctx, setSifts, email, siftId);
                  setIsUpdateLoading(false);
                  setExcludeSiftIds("");
                }}
              >
                Confirm Update All
              </Button>
            </div>
          </Dialog.Content.Body>
        </Dialog.Content>
      </Dialog>
      <Dialog
        isOpen={isStopSiftUpdatesDialogOpen}
        onOpen={setIsStopSiftUpdatesDialogOpen}
        size={"large"}
      >
        <Dialog.Content>
          <Dialog.Content.Header header="Are you sure?" />
          <Dialog.Content.Body>
            Clicking confirm will stop all sift updates in progress for{" "}
            <strong>{ALL_SIFTS[product]?.name}</strong>.
          </Dialog.Content.Body>
          <Dialog.Content.Actions justifyContent="space-between">
            <Flexbox>
              <Button
                onClick={async () => {
                  setIsStopLoading(true);
                  await stopSiftUpdates(product, ctx);
                  setIsStopLoading(false);
                  setIsUpdateLoading(false);
                  setIsStopSiftUpdatesDialogOpen(false);
                }}
              >
                Confirm
              </Button>
            </Flexbox>
          </Dialog.Content.Actions>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};

export default AdminSiftExplorer;
